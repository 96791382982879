
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import Multiselect from '@vueform/multiselect';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'districtTraineeSummary',
  components: {
    axios,
    Form,
    Datatable,
    SearchBar,
    Multiselect,
    Swal,
  },
  data() {
    return {
      api_url: '',
      load: false,
      loading: false,
      tranches_info: [] as any,
      TrancheInfos: [] as any,
      tranche_id: [] as any,
      tranche: [] as any,
      ethnic_id: "" as any,
      traineeDistrictData: [] as any,
      total_sum_district: [] as any,
      componentKey: 0,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranche();
    // await this.districtTrainee();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();

      formData.set('tranche', this.tranche_id);
      formData.set('ethnic', this.ethnic_id);

      let data = `${this.VUE_APP_API_URL}/api/report/district-trainee-summary_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },

    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche', this.tranche_id);
      formData.set('ethnic', this.ethnic_id);

      await ApiService.post('report/district-trainee-summary_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche', this.tranche_id);
      formData.set('ethnic', this.ethnic_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/district-trainee-summary_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'district-trainee-summary_export.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.tranches_info.push(obj);
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async districtTrainee() {
      if (this.tranche_id != '' && this.tranche_id != false) {
        this.load = true;

        await ApiService.get(
          'report/districtTrainee?tranche=' +
            this.tranche_id +
            '&ethnic=' +
            this.ethnic_id
        )
          .then((response) => {
            console.log(response);
            this.traineeDistrictData = response.data.data.district_info;
            this.total_sum_district = response.data.data.total_sum;

            this.componentKey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          text: 'Please Select Tranche!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        });
      }
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
